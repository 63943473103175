import { darken } from 'polished';

import {
  EN,
  EN_US,
  JA,
  LangType,
  ZH_HANT,
  ZH_HK,
  ZH_TW,
} from '@17live/app/constants';
import { I18nKeys } from '@17live/app/utils';
import getValueByTime from '@17live/app/utils/getValueByTime';

import {
  Currency,
  DotMoneyAccountStatus,
  DotMoneyErrorMessageKey,
  ErrorCode,
  IncomeTypeKey,
  KYCPage,
  MainPage,
  PayoutAgentKey,
  PayoutAgentParameter,
  WalletKey,
} from './enums';
import { MyRevenuePreference, PayoutAgentInfoCodeField, Tab } from './types';

export const RootPath = '/revenue';
export const WITHDRAWAL_DATE_ONLINE_TIME = new Date(
  '2024-02-26T00:00:00+08:00'
);

export const PageTabs: Array<{ messageID: I18nKeys; value: MainPage }> = [
  {
    messageID: 'bbfu_v2_title_revenue_manage',
    value: MainPage.Record,
  },
  {
    messageID: 'bbfu_v2_title_personal_information',
    value: MainPage.KYC,
  },
];

export const MyRevenueRoute = {
  Guide: `/${MainPage.Guide}`,
  Record: `/${MainPage.Record}`,
  Status: `/kyc/${KYCPage.Status}`,
  Term: `/kyc/${KYCPage.Term}`,
  Verify: `/kyc/${KYCPage.Verify}`,
  Profile: `/kyc/${KYCPage.Profile}`,
  Nationality: `/kyc/${KYCPage.Nationality}`,
};

export type IncomeTypeRoute = 'contract' | 'general' | 'reward';

export const IncomeTypeKeyToRoute: Record<IncomeTypeKey, IncomeTypeRoute> = {
  [IncomeTypeKey.Contract]: 'contract',
  [IncomeTypeKey.General]: 'general',
  [IncomeTypeKey.Reward]: 'reward',
};

export const IncomeTypeRouteToKey: Record<IncomeTypeRoute, IncomeTypeKey> = {
  contract: IncomeTypeKey.Contract,
  general: IncomeTypeKey.General,
  reward: IncomeTypeKey.Reward,
};

export const IncomeTypeTabs: Record<IncomeTypeKey, Tab<IncomeTypeKey>> = {
  [IncomeTypeKey.Contract]: {
    messageID: 'bbfu_v3_contract_revenue',
    value: IncomeTypeKey.Contract,
  },
  [IncomeTypeKey.General]: {
    messageID: 'bbfu_v3_user_revenue',
    value: IncomeTypeKey.General,
  },
  [IncomeTypeKey.Reward]: {
    messageID: 'bbfu_v3_17Q_reward',
    value: IncomeTypeKey.Reward,
  },
};

export const PAYOUT_AGENT_INFO_CODE_FIELD: Record<
  PayoutAgentKey,
  PayoutAgentInfoCodeField | null
> = {
  [PayoutAgentKey.LOCAL]: 'code',
  [PayoutAgentKey.FOREIGN]: 'swiftCode',
  [PayoutAgentKey.DOTMONEY]: null, // 不用顯示
};

export const CurrencyDigits: Record<Currency, number> = {
  [Currency.TWD]: 0,
  [Currency.JPY]: 0,
  [Currency.USD]: 2,
  [Currency.BBCOIN]: 0,
};

export const COLORS = {
  BLACK: '#28232d',
  LIGHT_BLACK: '#6f6777',
  WHITE: '#ffffff',
  DARKEN_WHITE: '#f7f7f7',
  LIGHT_GRAY: '#e6e6e6',
  DARK_GRAY: '#999999',
  PRIMARY: '#5071f6',
  DARK_PRIMARY: '#1a27ad',
  ACTIVE_TEXT: '#1a27ad',
  TEXT: '#28232d',
  SUB_TEXT: '#a9a4b0',
  LABEL_TEXT: '#28232d',
  BACKGROUND: '#f4f7ff',
  ACTIVE_BACKGROUND: '#f5f7ff',
  BORDER: '#d2d2d7',
  BD_GRAY: 'rgba(210,210,215,0.2)',
  DARK_BORDER: darken(0.2, '#d2d2d7'),
  GRADIENT: 'linear-gradient(to right, #b9c9ff, #4b73f3)',
  ERROR: '#d9344e',
  SUCCESS: '#32bf27',
  WRONG: '#f5487d',
};

export const RemarkExcerptLength: number = 12;

export const TWDWallets = [WalletKey.TWD_REVENUE_TW];

const taxReminderMsgDetail = getValueByTime<I18nKeys, I18nKeys>(
  'bbfu_v3_tax_reminder_msg_detail',
  'bbfu_v4_tax_reminder_msg_detail',
  WITHDRAWAL_DATE_ONLINE_TIME
);

export const TaxReminderDescriptionI18nKeys: Record<
  PayoutAgentKey,
  I18nKeys
> = {
  [PayoutAgentKey.LOCAL]: taxReminderMsgDetail,
  [PayoutAgentKey.FOREIGN]: taxReminderMsgDetail,
  [PayoutAgentKey.DOTMONEY]: 'dotmoney_tax_reminder_msg_detail',
};

export const PayoutAgentKeyToPayoutAgentParameter = {
  [PayoutAgentKey.LOCAL]: PayoutAgentParameter.LOCAL_BANK,
  [PayoutAgentKey.FOREIGN]: PayoutAgentParameter.FOREIGN_BANK,
  [PayoutAgentKey.DOTMONEY]: PayoutAgentParameter.DOTMONEY,
};

export const PayoutAgentParameterMessageKey: Record<
  PayoutAgentParameter,
  I18nKeys
> = {
  [PayoutAgentParameter.LOCAL_BANK]: 'withdrawal_type_local_bank',
  [PayoutAgentParameter.FOREIGN_BANK]: 'withdrawal_type_foreign_bank',
  [PayoutAgentParameter.DOTMONEY]: 'withdraw_type_dotmoney',
};

export const WithdrawErrorCodeMessageKey: Record<ErrorCode, I18nKeys> = {
  [ErrorCode.NOT_PERMITTED]: 'bbfu_login_error_nopermission',
  [ErrorCode.INVALID_PARAMS]: 'withdrawErrors_invalid_params',
  [ErrorCode.NOT_ENOUGHT_MOENY]: 'bbfu_step_withdrawl_error_amount_not_enough',
  [ErrorCode.NOT_PUBLIC_YET]: 'bbfu_step_withdrawl_error_not_release_yet',
  [ErrorCode.OVER_WITHDRAWAL_LIMIT]: 'bbfu_step_withdrawl_error_maximum_amount',
  [ErrorCode.UNDER_WITHDRAWAL_LIMIT]:
    'bbfu_step_withdrawl_error_minimum_amount',
  [ErrorCode.OVER_WITHDRAWAL_COUNT_LIMIT]:
    'bbfu_withdraw_pop_up_daily_quota_not_enough',
  [ErrorCode.FEE_IS_NOT_WAIVERABLE]: 'bbfu_v2_fee_is_not_waiverable',
  [ErrorCode.UNSPOPORTED_WALLET]: 'bbfu_v2_unsupported_transfer_wallet',
  [ErrorCode.OVER_SINGLE_UPPER_BOUND]: 'bbfu_v2_over_single_upper_bound',
  [ErrorCode.UNDER_SINGLE_LOWER_BOUND]: 'bbfu_v2_under_single_lower_bound',
  [ErrorCode.INCONSISTENT]: 'bbfu_v2_preview_inconsistent',
  [ErrorCode.OVER_TRANSFER_COUNT_LIMIT]:
    'bbfu_withdraw_pop_up_daily_quota_not_enough',
  [ErrorCode.DOTMONEY_ACCOUNT_NOT_FOUND]:
    DotMoneyErrorMessageKey.NoAccountFound,
  [ErrorCode.DOTMONEY_ACCOUNT_NOT_ACTIVATED]:
    DotMoneyErrorMessageKey.AccountNonactivated,
  [ErrorCode.DOTMONEY_BALANCE_OVER_MAXIMUM]:
    DotMoneyErrorMessageKey.BalanceOverMaximum,
  [ErrorCode.WALLET_WITHDRAW_COUNT_OVER_MONTHLY_MAXIMUM]:
    'bbfu_withdraw_pop_up_monthly_quota_not_enough',
};

export const TransactionStatusErrorMessageKey: Record<string, I18nKeys> = {
  'dotmoney.business.constraint_violation': DotMoneyErrorMessageKey.WrongAmount,
  'dotmoney.business.account_invalid_status':
    DotMoneyErrorMessageKey.AccountInvalid,
  'dotmoney.business.balance_overflow':
    DotMoneyErrorMessageKey.BalanceOverMaximum,
  'dotmoney.business.product_not_identified':
    DotMoneyErrorMessageKey.DotMoneyServerError,
  'dotmoney.business.request_id_already_exist':
    DotMoneyErrorMessageKey.DuplicateTransaction,
  'dotmoney.http.unauthorized': DotMoneyErrorMessageKey.DotMoneyServerError,
  'dotmoney.http.forbidden': DotMoneyErrorMessageKey.DotMoneyServerError,
  'dotmoney.http.not_found': DotMoneyErrorMessageKey.DotMoneyServerError,
  'dotmoney.http.internal_server_error':
    DotMoneyErrorMessageKey.DotMoneyServerError,
  'dotmoney.http.service_unavailable':
    DotMoneyErrorMessageKey.DotMoneyServerError,
};

export const DotMoneyAccountInvalidModalContent: Record<
  Exclude<DotMoneyAccountStatus, DotMoneyAccountStatus.Active>,
  I18nKeys
> = {
  [DotMoneyAccountStatus.Frozen]: 'account_error_reminder_des_1',
  [DotMoneyAccountStatus.Black]: 'account_error_reminder_des_2',
  [DotMoneyAccountStatus.Invalid]: 'account_error_reminder_des_3',
};

export const DefaultMyRevenuePreference: MyRevenuePreference = {
  // for displayed once tooltips
  tooltips: {},
};

export const ManualPDFGCSFile: Record<LangType, string> = {
  [ZH_HANT]: '/17live/manual/myrevenue-zh-hant.pdf',
  [ZH_TW]: '/17live/manual/myrevenue-zh-hant.pdf',
  [ZH_HK]: '/17live/manual/myrevenue-zh-hant.pdf',
  [JA]: '/17live/manual/myrevenue-ja.pdf',
  [EN_US]: '/17live/manual/myrevenue-en.pdf',
  [EN]: '/17live/manual/myrevenue-en.pdf',
};
