export enum MainPage {
  Record = 'record',
  KYC = 'kyc',
  Guide = 'guide',
}

export enum KYCPage {
  Status = 'status',
  Term = 'term',
  Verify = 'verify',
  Profile = 'profile',
  Nationality = 'nationality',
}

export enum Currency {
  USD = 'USD',
  JPY = 'JPY',
  TWD = 'TWD',
  BBCOIN = 'BBCOIN',
}

export enum IncomeTypeKey {
  Contract = 'ContractRevenue',
  General = 'GeneralRevenue',
  Reward = 'Reward',
}

export enum TransactionCategory {
  TRANSFER = 1,
  WITHDRAW = 2,
  REMITTANCE = 3,
  REMITTANCE_EXPIRE = 8,
}

export enum PayoutAgentParameter {
  LOCAL_BANK = 0,
  FOREIGN_BANK = 1,
  DOTMONEY = 3,
}

export enum TransactionStatus {
  BILLED = 'Billed',
  UNPAID = 'Unpaid',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  REFUND = 'Refund',
  WRITTEN_OFF = 'WrittenOff',
}

export enum RemittanceType {
  Type17Revenue = '17_Revenue',
  Type17Reward = '17_Reward',
  Type17ContractMonthlyIncome = '17_ContractMonthlyIncome',
  Type17CombinePay = '17_CombinePay',
  Type17UnbilledRevenue = '17_UnbilledRevenue',
  Type17RemainingVerifiedRevenue = '17_RemainingVerifiedRevenue',
}

export enum StatusKey {
  InProgress = 'bbfu_money_transfer_result_in_progress',
  Completed = 'bbfu_money_transfer_result_completed',
  Refund = 'bbfu_money_transfer_result_refund',
  Claimable = 'bbfu_remittance_claimable',
  Claimed = 'bbfu_remittance_claimed',
  Expired = 'bbfu_remittance_expired',
}

export enum WithdrawFeeType {
  HAS = 0,
  DEDUCT = 1,
  DEDUCT2 = 2,
}

export enum WalletKey {
  USD_REVENUE_NONE_BBCOIN = 'USD_RevenueNONE_BBCOIN',
  TWD_REVENUE_TW_BBCOIN = 'TWD_RevenueTW_BBCOIN',
  USD_REVENUE_NONE = 'USD_RevenueNONE',
  USD_REWARD = 'USD_Reward',
  TWD_REVENUE_TW = 'TWD_RevenueTW',
  USD_WALLET = 'USDWallet',
  JPY_REVENUE_JP_NONE = 'JPY_RevenueJP_NonContract',
  JPY_REVENUE_JP = 'JPY_RevenueJP',
}

export enum WalletAction {
  Transfer,
  Withdraw,
}

export enum PayoutAgentKey {
  LOCAL = 'localBankInfo',
  FOREIGN = 'foreignBankInfo',
  DOTMONEY = 'dotMoneyInfo',
}

export enum DotMoneyAccountStatus {
  Active = 'ACTIVE',
  Frozen = 'FROZEN',
  Black = 'BLACK',
  Invalid = 'INVALID',
}

export enum DotMoneyErrorMessageKey {
  WrongAmount = 'dotmoney_error_1',
  NoAccountFound = 'dotmoney_error_2',
  AccountNonactivated = 'dotmoney_error_3',
  BalanceOverMaximum = 'dotmoney_error_4',
  DuplicateTransaction = 'dotmoney_error_5',
  UnauthorizedTransaction = 'dotmoney_error_6',
  UnauthorizedIP = 'dotmoney_error_7',
  DotMoneyServerError = 'dotmoney_error_8',
  UnauthorizedRequest = 'dotmoney_error_9',
  AccountFrozen = 'dotmoney_account_error_1',
  AccountBlocked = 'dotmoney_account_error_2',
  AccountInvalid = 'dotmoney_account_error_3',
}

export enum ErrorCode {
  NOT_PERMITTED = 5,
  INVALID_PARAMS = 7,
  NOT_ENOUGHT_MOENY = 16,
  NOT_PUBLIC_YET = 38000,
  OVER_WITHDRAWAL_LIMIT = 38001,
  UNDER_WITHDRAWAL_LIMIT = 38002,
  OVER_WITHDRAWAL_COUNT_LIMIT = 38003,
  FEE_IS_NOT_WAIVERABLE = 38004,
  UNSPOPORTED_WALLET = 38005,
  OVER_SINGLE_UPPER_BOUND = 38006,
  UNDER_SINGLE_LOWER_BOUND = 38007,
  INCONSISTENT = 38008,
  OVER_TRANSFER_COUNT_LIMIT = 38009,
  DOTMONEY_ACCOUNT_NOT_FOUND = 38012,
  DOTMONEY_ACCOUNT_NOT_ACTIVATED = 38013,
  DOTMONEY_BALANCE_OVER_MAXIMUM = 38014,
  WALLET_WITHDRAW_COUNT_OVER_MONTHLY_MAXIMUM = 38015,
}

export enum AuthForType {
  DotMoney = 'dotmoney',
}

export enum CountryCode {
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AR = 'AR',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  BD = 'BD',
  BE = 'BE',
  BH = 'BH',
  BM = 'BM',
  BO = 'BO',
  BR = 'BR',
  BT = 'BT',
  CA = 'CA',
  CG = 'CG',
  CH = 'CH',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  DE = 'DE',
  DK = 'DK',
  DO = 'DO',
  EG = 'EG',
  ES = 'ES',
  FR = 'FR',
  FI = 'FI',
  FJ = 'FJ',
  GD = 'GD',
  GE = 'GE',
  GH = 'GH',
  GR = 'GR',
  GT = 'GT',
  GY = 'GY',
  HK = 'HK',
  HN = 'HN',
  HT = 'HT',
  ID = 'ID',
  IE = 'IE',
  IN = 'IN',
  IS = 'IS',
  IT = 'IT',
  IQ = 'IQ',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KH = 'KH',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KZ = 'KZ',
  LC = 'LC',
  LK = 'LK',
  LU = 'LU',
  MA = 'MA',
  MG = 'MG',
  MK = 'MK',
  ML = 'ML',
  MO = 'MO',
  MV = 'MV',
  MX = 'MX',
  NG = 'NG',
  NI = 'NI',
  NO = 'NO',
  NR = 'NR',
  NZ = 'NZ',
  PA = 'PA',
  PG = 'PG',
  PK = 'PK',
  PT = 'PT',
  PY = 'PY',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SV = 'SV',
  SY = 'SY',
  TO = 'TO',
  TR = 'TR',
  TL = 'TL',
  TW = 'TW',
  UA = 'UA',
  UG = 'UG',
  UK = 'UK',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VE = 'VE',
  YE = 'YE',
}

export enum MyRevenueTooltip {
  NewWithdrawMethodAlert = 'newWithdrawMethodAlert',
}

export enum EmailVerifyStatus {
  Unknown,
  Wait,
  Done,
}

export enum KYCStatus {
  Unknown,
  Unfinished,
  Finished,
}
