/**
 * 依據 targetTime 來決定要顯示 before or after 內容
 * @param before will be return when refTime < targetTime
 * @param after will be return when refTime >= targetTime
 * @param targetTime
 * @param refTime optional, default is current time `new Date()`
 * @returns before or after
 */
function getValueByTime<B, A>(
  before: B,
  after: A,
  targetTime: Date,
  refTime: Date = new Date()
): B | A {
  const targetDateTime = targetTime.getTime();
  const currentDateTime = refTime.getTime();

  if (currentDateTime < targetDateTime) {
    return before;
  }

  return after;
}

export default getValueByTime;
