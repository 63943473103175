import apis from './shared/apis';

const API_PATH = 'kyc';

// GET /kyc/info/status
// Resp:
// {
//  currentTermVersion: <string>, <YYYYMMDD>
//  termVersion: <string>, KYC-<region><YYYYMMDD>
//  emailVerifyStatus: <int>, // 0 - unknown, 1 - wait, 2 - done
//  kycStatus: <int>, // 0 - unknown, 1 - unfinished, 2 - finished
//  nationality: <string>
// }
export const getKYCStatus = () => apis.getJSON(`${API_PATH}/info/status`);

// POST /kyc/info/terms/:version
// Resp: 201
export const setKYCTermsVersion = version =>
  apis.postJSON(`${API_PATH}/info/terms/${version}`);

// POST /auth/email/send
// {
//  thirdParty: <int>,  // 0 - Unknown, 1 - Paypal, 2 - Mith, 3 - KYC
//  verifyURL: <string>,
//  email: <string>,
// }
export const sendEmailVerifyCode = ({ thirdParty, email }) =>
  apis.postJSON(
    `${API_PATH}/email/send`,
    {
      thirdParty,
      verifyURL: window.location.href,
      email,
    },
    {},
    { retries: 0 }
  );

// POST /auth/email/verify
// {
//  jwt: <string>,
// }
export const verifyEmailWebToken = jwt =>
  apis.postJSON(`${API_PATH}/email/verify`, { jwt });

// GET /kyc/info
// Resp:
// {
//   detail: {
//     firstName: <string>,
//     lastName: <string>,
//     countryCode: <string>,
//     phoneNumber: <string>,
//     address: <string>,
//     dob: <string>,
//     nationality: <string>,
//   },
//   document: {
//     type: <int>,
//     firstName: <string>,
//     lastName: <string>,
//     dob: <string>,
//     documentNumber: <string>,
//     issueDate: <string>,
//     expiryDate: <string>,
//     issueBy: <string>,
//     issueType: <int>,
//     permanentAddress: <string>,
//     correspondenceAddress: <string>,
//     frontFileKey: <string>,
//     backFileKey: <string>,
//   },
//   account: {
//     type: <int>,
//     payeeName: <string>,
//     bankName: <string>,
//     bankCode: <string>,
//     branchName: <string>,
//     branchCode: <string>,
//     account: <string>,
//     swiftCode: <string>,
//     fileKey: <string>,
//     payeeType: <number>, // 0 - Others, 1 - USER_LOCAL(JP), 2 - LEGAL(JP)
//     healthInsuranceType: <number>,  // 0 - NA, 1 - WITH_HEALTH_INSURANCE, 2 - WITHOUT_HEALTH_INSURANCE, 3 - UNKNOWN
//   },
// }
export const getKYCMe = () => apis.getJSON(`${API_PATH}/me`);
export const getKYCMeV2 = () => apis.getJSON(`${API_PATH}/info`);

// POST /kyc/info
// {
//  detail: {
//    firstName: <string>,
//    lastName: <string>,
//    countryCode: <string>,
//    phoneNumber: <string>,
//    address: <string>,
//    dob: <string>, // yyyy-mm-dd
//    nationality: <string>,
//  },
//  document: {
//    type: <int>, // 1 - 身分證, 2 - 駕照, 3 - 護照, 4 - 居留證
//    firstName: <string>,
//    lastName: <string>,
//    dob: <string>, // yyyy-mm-dd
//    documentNumber: <string>,
//    issueDate: <string>, // yyyy-mm-dd
//    expiryDate: <string>, // yyyy-mm-dd
//    issueBy: <string>,
//    issueType: <int>, // 1 - 初發, 2 - 換發, 3 - 補發
//    permanentAddress: <string>,
//    correspondenceAddress: <string>,
//    frontFileKey: <string>,
//    backFileKey: <string>,
//  },
//  account: {
//    type: <int>, // 1 - Local Bank, 2 - Foreign Bank
//    payeeName: <string>,
//    payeeType: <number>, // 0 - Others, 1 - USER_LOCAL(JP), 2 - LEGAL(JP)
//    healthInsuranceType: <number>, // 0 - NA, 1 - WITH_HEALTH_INSURANCE, 2 - WITHOUT_HEALTH_INSURANCE, 3 - UNKNOWN
//    bankName: <string>,
//    bankCode: <string>,
//    branchName: <string>,
//    branchCode: <string>,
//    account: <string>,
//    swiftCode: <string>,
//    fileKey: <string>,
//  },
// }
export const postKYCMe = profile =>
  apis.postJSON(`${API_PATH}/me`, profile, {}, { retries: 0 });
export const putKYCMe = profile =>
  apis.putJSON(`${API_PATH}/me`, profile, {}, { retries: 0 });

export const postKYCMeV2 = profile =>
  apis.postJSON(`${API_PATH}/info`, profile, {}, { retries: 0 });
export const putKYCMeV2 = profile =>
  apis.putJSON(`${API_PATH}/info`, profile, {}, { retries: 0 });

// POST /kyc/files
// Resp:
// {
//  fileKey: <string>,
// }
export const uploadKYCFile = formData =>
  apis.file({
    endpoint: `${API_PATH}/files`,
    payload: formData,
  });

// GET /kyc/files/:fileKey
export const getKYCFilesKey = fileKey =>
  apis.getJSON(`${API_PATH}/files/${fileKey}`);

// POST /kyc/info/nationality
// Paylaod {
//  nationality: <string>,
// }
export const updateNationality = nationality =>
  apis.postJSON(
    `${API_PATH}/info/nationality`,
    {
      nationality,
    },
    {},
    { retries: 0 }
  );

// GET /kyc/addresses?region=
export const getAddresssByCode = ({ region, postalCode }) =>
  apis.getJSON(
    `${API_PATH}/addresses?region=${region}&postalCode=${postalCode}`
  );
